import { BrowserRouter as Router } from 'react-router-dom';
import { ApiClientProvider } from './api';
import { Layout, LoadingProvider, SnackbarProvider, ModuleProvider } from './components';
import { AppContextProvider } from './context';
import { Routes } from './routes';
import { ThemeProvider } from './styles';
import { LocalizationProvider, TranslationProvider } from './translation';
import { WindowSizeProvider } from './utils';

export const App = () => {
  return (
    <TranslationProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <LoadingProvider>
            <Router>
              <AppContextProvider>
                <ApiClientProvider>
                  <LocalizationProvider>
                    <WindowSizeProvider>
                      <ModuleProvider>
                        <Routes />
                      </ModuleProvider>
                    </WindowSizeProvider>
                  </LocalizationProvider>
                </ApiClientProvider>
              </AppContextProvider>
            </Router>
          </LoadingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </TranslationProvider>
  );
};
