import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Content } from './Content';
import { useRoutes } from '@/routes';
import { useAppContext } from '@/context';
import { appHeaderHeight } from '@/styles';
import { removeStyledSmallScreenProp, useSmallScreen, useWindowSize } from '@/utils';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  const { initialized } = useAppContext();
  const { width } = useWindowSize();

  if (!initialized || !width) {
    return <></>;
  }

  return <LayoutComponent />;
};

const LayoutComponent = () => {
  const { loggedIn } = useAppContext();
  const routes = useRoutes();
  const smallScreen = useSmallScreen();

  const [sidebarOpen, setSidebarOpen] = useState(!smallScreen);
  const [openedSidebarItem, setOpenedSidebarItem] = useState<string | undefined>(
    routes.find(
      (route) => route.items && route.items.find((item) => item.path === location.pathname),
    )?.label,
  );

  const toggleSidebar = () => setSidebarOpen((open) => !open);
  const toggleSidebarItem = (item?: string) =>
    setOpenedSidebarItem(!item || item === openedSidebarItem ? undefined : item);

  return (
    <Container {...{ smallScreen }}>
      <Header {...{ sidebarOpen, toggleSidebar }} />
      <Wrapper>
        {loggedIn && (
          <Sidebar
            {...{
              open: sidebarOpen,
              items: routes,
              openedItem: openedSidebarItem,
              onToggleItem: toggleSidebarItem,
              onClose: toggleSidebar,
            }}
          />
        )}
        <Content
          {...{
            fullWidth: !sidebarOpen,
            toggleFullWidth: toggleSidebar,
            collapseEnabled: loggedIn,
          }}
        >
          <Outlet />
        </Content>
      </Wrapper>
    </Container>
  );
};

const Container = styled(
  Box,
  removeStyledSmallScreenProp,
)<{ smallScreen: boolean }>(({ smallScreen }) => ({
  width: '100vw',
  height: '100vh',
  ...(smallScreen ? { overflowY: 'auto' } : {}),
}));

const height = `calc(100% - ${appHeaderHeight}px)`;

const Wrapper = styled(Box)({
  display: 'flex',
  height,
  maxHeight: height,
  minHeight: height,
});
