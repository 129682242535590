import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { noAuthRoutes, useRoutes, userMenuRoutes } from './items';
import { Route as RoutePath } from '@/enums';
import { useAppContext } from '@/context';
import { GroupRouteItem, RouteItem } from './types';
import { AuthorizationSuccess } from '@/pages/BankAuthorizations/AuthorizationSuccess';
import { Layout } from '@/components';

export const Routes = () => {
  const { loggedIn } = useAppContext();
  const routes = useRoutes();
  const applicableRoutes = loggedIn ? getAuthorizedRoutes(routes) : noAuthRoutes;
  const routeElements = applicableRoutes
    .filter((item) => 'page' in item && item.page && 'path' in item && item.path)
    .map(mapRoute);
  const redirectElement = (
    <Navigate to={loggedIn ? RoutePath.Dashboard : RoutePath.Login} replace />
  );

  return (
    <RouterRoutes>
      <Route path='/' element={<Layout />}>
        {routeElements}
        <Route index element={redirectElement} />
      </Route>
      <Route path='/authorizationsuccess' element={<AuthorizationSuccess />} />
      <Route path='*' element={redirectElement} />
    </RouterRoutes>
  );
};

const getAuthorizedRoutes = (routes: GroupRouteItem[]): RouteItem[] => {
  const topLevelRoutes = routes.map(({ items, ...rest }) => rest);
  const subroutes = routes.flatMap((x) => x.items ?? []);

  return [...topLevelRoutes, ...subroutes, ...userMenuRoutes];
};

const mapRoute = ({ path, page: module }: RouteItem) => {
  const Module = module as () => JSX.Element;
  return <Route key={path} path={path} element={<Module />} />;
};
